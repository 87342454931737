import './main.scss';
import {useEffect, useContext} from 'react';
import {ThemeContext} from './App'
import {returnImgPath ,goApp/*isElementVisibleInScrollContainer,returnNext*/} from './functions'
import Store  from "./store"
import Faq  from "./faq"


function Header()
{
const utm=(window.location.search!=='')?'&'+window.location.search.substr(1,999):''
const {theme} = useContext(ThemeContext);
return(
<div className="main">
		<img  src={returnImgPath(theme,"header_bg_all.png")} alt="" className="main_center_img" onLoad={(e)=>e.target.style.opacity=1}  />
	<div className="main_bottom">
		<div className="main_bottom_popup">
			<div className="main_bottom_popup_wrapper">
<span onClick={()=>goApp()} className="main_link">Protone</span> — приложение, <br />в котором пользователи, выполняя миссии и прокачивая своих аватаров, делают мир лучше
			</div>
		</div>
		<img  src={returnImgPath(theme,"qr.png")} alt="" className="main_qr" />
			<div className="main_bottom_mobile">
<a href={"https://redirect.appmetrica.yandex.com/serve/749267348866796478?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&ios_ifa={IOSIFA}&ios_ifa_sha1={IDFA_LC_SH1}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"button_apple.svg")} alt="" className="main_app_apple" /></a>
<a href={"https://redirect.appmetrica.yandex.com/serve/677209770347726262?click_id={LOGID}&google_aid={GOOGLEAID}&android_id={ANDROIDID}&android_id_sha1={ANDROID_ID_LC_SH1}&search_term={keyword}&google_aid_sha1={GOOGLE_AID_LC_SH1}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"button_google.svg")} alt="" className="main_app_google" /></a>
			</div>
		</div>
</div>
)
}
	
function Main()
{
useEffect(() => {
  if (window.location.hash!=='') 
{
//исправляет баг некоторых браузеров по работе хеша и реакта
	window.setTimeout(()=>{
const cur=window.location.hash
window.location.hash=''
window.location.hash=cur
  },30)
}	
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]);
return (
<div>
  <img src="/i/top1.jpg" className="top_b" alt="" />
{/*<Header />*/}
<div name="store" aria-label="store"  aria-hidden="true" />
<Store />
<div name="faq" aria-label="faq"  aria-hidden="true" />
<Faq />
</div>
	)
}

export default Main;
