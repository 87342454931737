import React from 'react'
import './notfound.scss'
function NotFound()
{
return(
<div className="notfound">
<div className="title">404</div>
<div className="text">Данная страница не найдена &mdash; но вы можете выбрать другую страницу в меню, или<br /><a href="/">перейти на главную</a></div>
</div>
)
}


export default NotFound;