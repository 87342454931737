import React from 'react'
import {useEffect,useState, useRef, memo, useContext} from 'react';
import {ThemeContext} from './App'
import { useForm} from 'react-hook-form';
import axios from 'axios';
import Images from "./image"
import useErrorHandler from './error/useErrorHandler'
import {cards} from "./data"
import './card.scss'
import {animateDivScroll,sanitTags, returnImgPath/*,useEvent*//*,encodeDataToURL*//*,elementVisible*/} from './functions'
import { useParams,useNavigate } from "react-router-dom";

var classNames = require('classnames');

const Slider=memo((props)=>
{
const thumbScroll=useRef(null);
const [current,setCurrent]=useState(props.current)
//const a_img=(props.item.slider!=='undefined')?props.item.slider:[];
useEffect(() => {
if (thumbScroll.current===null) return
const scrollTo=thumbScroll.current.childNodes[(current===-1)?0:current].offsetLeft-thumbScroll.current.offsetLeft
//  if (props.current!=current) props.setCurrent(current);
  animateDivScroll(thumbScroll.current,scrollTo,1000)
}, [current]);
  useEffect(() => {
setCurrent(props.current) // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.current]);
  /*
useEffect(() => {
window.setTimeout(function(){setCurrent(props.current)},100) // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.current]);*/

  if (props.show===false || props.aimg.length===0) return ''
//prepare thumbnails
let thumbs=[]
  for (var i=0; i<props.aimg.length; i++)
   {
let temp=i
   thumbs.push(<img src={props.aimg[i].preview} alt="" key={"thumb_"+i} onClick={(e)=>{(e).stopPropagation();setCurrent(temp)}} className={classNames("image",{"image_act":(current===i)?true:false})} />)
   }
//
const scroller=(e)=>
{
let scrollTo;
e.preventDefault()
  if (e.deltaY>=-15 && e.deltaY<=15) scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*40);
  else scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*5);
thumbScroll.current.scrollTo({
  left:scrollTo,
  behavior: "auto"
});
//animateDivScroll(thumbScroll.current,scrollTo,1000)
}



return(
<div className="popup_main popup_slider">
<div className="slider_container"><Images current={current} setcur={(e)=>{setCurrent(e)}} close={props.close} arr={props.aimg} arrows={true} swipe={true} click="once" /></div>
<div className="images" ref={thumbScroll} onWheel={(e)=>{scroller(e);}}>{thumbs}</div>
</div>
)
}, (prevProps, nextProps) => prevProps.current===nextProps.current)

/* eslint-disable no-useless-escape*/
/*
const handlePhone = (event) =>
{
  const { key } = event;
  const isValidInput = /^\d*[\s()+\-]*\d*$/.test(key);
  if (!isValidInput && key !== '  ' && key!=='ArrowLeft' && key!=='ArrowRight' && key!=='Tab' && key!=='Enter' && key!=='Backspace') 
  {
  event.preventDefault();
   }
};
*/
//return initial selected item for options
const returnSelected=(id,tip,arr)=>
{
  if (typeof parseInt(id)==='undefined' || id<0 || id>arr.length-1 || arr.disabled===true || typeof arr.opt==='undefined') return 0
const item=arr.opt
  for (var i=0; i<item.length; i++)
  {
    if (item[i].name===tip && parseInt(item[i].selected)>-1 && parseInt(item[i].selected)<item[i].arr.length && item[i].arr[parseInt(item[i].selected)].status===true )
    {
return parseInt(item[i].selected)
   }
  }
return 0
}
function StoreCard(props)
{
const navigate = useNavigate();
 let { id } = useParams();
const setVisibleError = useErrorHandler()
const [loading, setLoading]=useState(false)
const [thankyou, setThankyou]=useState(false)
const [agree, setAgree]=useState(false)
const {register, handleSubmit,  formState: { errors},reset, setValue } = useForm();
const {theme} = useContext(ThemeContext);
const [formOptions,setFormOptions]=useState({color:returnSelected(id,'color',cards[id]),size:returnSelected(id,'size',cards[id])})
const [currentSlider, setCurrentSlider]=useState(0)
let timerThankYou=useRef();
let item=false
useEffect(() => {
window.scrollTo(0,0); // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  if (typeof parseInt(id)==='undefined' || id<0 || id>cards.length-1 || cards[id].disabled===true /*|| cards[id].coming===true*/) 
  {
window.setTimeout(()=>navigate('/'))
return ''
  }
  else item=cards[id]
const countForm=(!isNaN(parseInt(item.price.replaceAll(' ',''))))?parseInt(item.price.replaceAll(' ','')):1
const onSubmit=data=>sendMe(data)
function showOk()
{
setThankyou(<span style={{color:"var(--accent-color",fontSize:"20px"}}>Ваша заявка успешно создана!<br />Наши менеджеры свяжутся с вами в телеграм в ближайшее время.<br />Мы работаем с 10:00 до 19:00 в рабочие дни.</span>)
timerThankYou.current=window.setTimeout(function(){setThankyou(false);close(true);/*setLoading(false)*/},10000) //10 000
}
const sendMe=(data)=>
{
if (loading) return
setLoading(true)
setThankyou('')
//data.comment='Выбран товар: '+sanitTags(item.title)+'\n'+data.comment /*\n*/
//data.comment=data.comment.replaceAll('\n',' ')
data.productName=sanitTags(item.title)
axios(
{
  method: 'post',
   headers: { 'Content-Type': 'application/json' },
  url: 'https://api.ru.protone.app/protone-store/verify-protone-coin',
  data: JSON.stringify(data)
})
.then(response=> { 

  if (response.data.isSuccess)
   {
//setLoading(false)
reset()
showOk()
//alert('Thank you')
   }
  else
   {
setThankyou(<span style={{color:"red"}}>{response.data.errorMessage}</span>)
//alert('Something bad. Information:'+response.data)

	setLoading(false)
   }
//console.log(response);
})
.catch(function (error) {
setVisibleError('Не удается связаться с сервером',error)
//alert('Something bad. Information: '+error)
setLoading(false)
//console.log(error);
});
}
const close=(e)=>
{//return false
  if (e===true) 
   {
window.clearTimeout(timerThankYou.current)
navigate('/')
/*
setClosePopup(true)
window.setTimeout(()=>{props.close()},300)
*/
   }
  else if (e.target.className==='popup_wrapper')
   {
window.clearTimeout(timerThankYou.current)
navigate('/')
/*
setClosePopup(true)
window.setTimeout(()=>{props.close()},300)
*/
   }
}
const ItemColors=(props)=>
{
const {name,arr}=props
const handlerClick=(item,i)=>
{ 
  if (typeof item.slider!=='undefined') setCurrentSlider(parseInt(item.slider))
setFormOptions({...formOptions,[name]:i})
setValue(name, item.value);
}
const rez=arr.map((item,i)=><div key={i} data-test={item.bg} className={classNames("popup_option",{"popup_option_select":(formOptions[name]===i),"popup_option_disabled":!item.status})} onClick={()=>{handlerClick(item,i)}} style={{background:(item.bg)?`url('${item.bg}')`:item.value}}></div>)
return(
<div className="popup_options">
<input type="hidden" name={name} value={arr[formOptions[name]].value} {...register(name, { required: true, maxLength: 255 })} />
{rez}
</div>
)
}
const ItemText=(props)=>
{
const {name,arr}=props
const rez=arr.map((item,i)=><div key={i} className={classNames("popup_option",{"popup_option_select":(formOptions[name]===i),"popup_option_disabled":!item.status})} onClick={()=>{setFormOptions({...formOptions,[name]:i});setValue(name, item.value);}}>{item.value}</div>)
return(
<div className="popup_options popup_options_text">
<input type="hidden" name={name} value={arr[formOptions[name]].value} {...register(name, { required: true, maxLength: 255 })} />
{rez}
</div>
)
}
const Options=({item})=>
{
 /*useEffect(() => { 
if (typeof item==="undefined")  return
  for (var i=0; i<item.length; i++)
   {
const n=item[i].name;
     if (typeof formOptions[n]==='undefined') setFormOptions({...formOptions,[n]:0})
    }
}// eslint-disable-next-line react-hooks/exhaustive-deps
,[]);*/
  if (typeof item==="undefined") return ''
var rez=[]; 
  for (var i=0; i<item.length; i++)
  {
    if (item[i].tip==='color')  rez.push(<div key={i} className="popup_options_wrapper"><div className="popup_options_title">{item[i].title}</div><ItemColors arr={item[i].arr} name={item[i].name} /></div>)
    if (item[i].tip==='text') rez.push(<div  key={i} className="popup_options_wrapper"><div className="popup_options_title">{item[i].title}</div><ItemText arr={item[i].arr} name={item[i].name} /></div>)
   }
return(
<div>
{rez}
</div>
)
}
const getTextValue=(item,real=false)=>
{
  switch (item)
   {
  case "comment":
    if (real && localStorage.getItem('comment_address')!==null)  return  localStorage.getItem('comment_address')
    if (!real && localStorage.getItem('comment')!==null)  return  localStorage.getItem('comment')
    break;
  default:
        if (localStorage.getItem(item)!==null) return  localStorage.getItem(item)
   }
}
const setTextValue=(item,value,real=false)=>
{
  switch (item) 
   {
  case "comment":
    if (real)  localStorage.setItem('comment_address',value)
    else localStorage.setItem('comment',value)
    break;
  default:
       localStorage.setItem(item,value)
   }
}
//console.log(errors.agree)
// lint don't like regexp
/* eslint-disable no-useless-escape*/
//{/*<input defaultValue={getTextValue("phone")} disabled={loading} type="" name="phone" onKeyDown={handlePhone}  className={(errors.phone)?"input_error":""} placeholder="+7 (___) ___ ____" {...register('phone', { required: true, pattern: /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im })}  onClick={(e)=>{e.target.select()}} onChange={(e)=>setTextValue('phone',e.target.value,item.real)} />*/}
//
const retSuffix=(i,count=true)=>
{
const arr=(count)?['штука','штуки','штук']:['Осталась','Осталось','Осталось']
return arr[(i%100>4 && i%100<20) ? 2 : [2,0,1,1,1,2][(i%10<5) ? i%10 : 5]];
}
const countDesktop=(isNaN(parseInt(item.count)))?item.count:<div>{retSuffix(item.count,false)} {item.count} {retSuffix(item.count)}</div>
const countMobile=(isNaN(parseInt(item.count)))?item.count:<div><span>{retSuffix(item.count,false)}</span><br />{item.count} {retSuffix(item.count)}</div>
return(
<div className="store_popup">
<div className={classNames("popup_wrapper",{/*"close_popup":closePopup*/})} onClick={(e)=>close(e)}>
	<div  className={classNames("popup",{"popup_is_slider":(typeof item.slider!=="undefined")})}>
	<div className="popup_top">
<img src={returnImgPath(theme,"back.svg")}  alt="" className="popup_close" onClick={()=>close(true)} />
<div className="popup_count popup_count_desktop"><div className="wrapper"><img src="/i/popup_coin.png" alt="" title="Протоны" />{item.price}</div><div className="big_counter">{countDesktop}</div></div>
<div className="popup_title" dangerouslySetInnerHTML={{__html: item.title}}></div>
	</div>

{(typeof item.slider==='undefined')?<div className="popup_main"><img src={"/i/store/big/"+item.bg} alt="" className="popup_main_img" onLoad={(e)=>e.target.style.opacity=1} /></div>:<Slider current={currentSlider} aimg={item.slider} item={item} close={()=>{return false}} />}
<div className="popup_form">
<div className="popup_count popup_count_mobile"><div className="wrapper"><img src="/i/popup_coin.png" alt="" title="Протоны" />{item.price}</div><div className="big_counter">{countMobile}</div></div>
<div className="popup_botttom_text" dangerouslySetInnerHTML={{__html: item.bigtext}}></div>
<form onSubmit={handleSubmit(onSubmit)}>
<Options item={item.opt} />
	<div className="title">Заполните форму покупки:</div>
	<input type="hidden" name="count" value={countForm} {...register("count", { required: true, maxLength: 255 })} />
	<div className="store_popup_inputs_wrapper">
<div className="div_impact_id"><input defaultValue={getTextValue("impactId")} disabled={loading} type="" name="impactId"  className={classNames("input_impact",{"input_error":errors.impactId})} placeholder="Импакт ID" {...register('impactId', { required: true, maxLength: 255 })}  onClick={(e)=>{e.target.select()}}  onChange={(e)=>setTextValue('impactId',e.target.value,item.real)}  /><div className="quest" onClick={()=>alert('“Найти свой импакт ид Вы можете в приложении Protone.app, перейдя в раздел “Настройки” и далее открыв вкладку “Профиль”')}  title="“Импакт ID Вы можете найти в приложении Protone.app, перейдя в раздел “Настройки” и далее открыв вкладку “Профиль”">?</div></div>
<input defaultValue={getTextValue("telegramUsername")} disabled={loading} type="" name="telegramUsername"  className={(errors.telegramUsername)?"input_error":""} placeholder="Ник в Telegram" {...register('telegramUsername', { required: true,  maxLength: 255 })}  onClick={(e)=>{e.target.select()}} onChange={(e)=>setTextValue('telegramUsername',e.target.value,item.real)} />
	
	</div>
<textarea defaultValue={getTextValue("comment",item.real)} disabled={loading} name="comment" id="comment" className={(errors.comment)?"input_error":""} placeholder={(item.real)?"Адрес: Страна, город, улица, дом, кв., индекс":"Комментарий"} {...register('comment', { required: false, maxLength: 255 })}  onClick={(e)=>{e.target.select()}} onChange={(e)=>setTextValue('comment',e.target.value,item.real)} ></textarea>
	
<div><label className="wrapper_checkbox">
<div className="popup_check_text">Я принимаю <a href="https://protone.app/eula_protone.pdf" target="_blank" rel="noreferrer">условия пользовательского соглашения</a> и даю своё согласие на обработку моих персональных данных</div>
<input type="checkbox" name="agree" /*{...register('agree', { required: true})}*/ onChange={(e)=>setAgree(e.target.checked)}  /><span className="checkmark"></span>
</label></div>
	<div className="popup_button_wrapper">
		<div className={classNames("button",{"button_disabled":(loading || !agree)})}  onClick={handleSubmit(onSubmit)}>Отправить</div>
	</div>
	<div className="hided_error">{thankyou}
{(errors.impactId)?<div style={{color:"red"}}>Поле Импакт ID не заполнено</div>:''}
{(errors.phone)?<div style={{color:"red"}}>Укажите мобильный телефон</div>:''}
{(errors.telegramUsername)?<div style={{color:"red"}}>Укажите свой ник в Telegram</div>:''}
{/*(errors.agree)?<div style={{color:"red"}}>Примите условия пользовательского соглашения</div>:''*/}
</div>
</form>
</div>

	</div>
</div>
</div>
)
}

export default StoreCard;